import {
  AddCircle,
  Apps,
  Build,
  CloudUploadRounded,
  Code,
  CodeOutlined,
  ConfirmationNumber,
  Dashboard,
  DeveloperMode,
  DynamicFeed,
  ExpandLess,
  ExpandMore,
  ExposureNeg1,
  ExposurePlus2,
  FindInPage,
  FormatPaint,
  GroupAdd,
  Keyboard,
  LibraryAddCheck,
  LiveTv,
  MonetizationOn as MonetizationOnIcon,
  Money,
  Notifications,
  QuestionAnswer,
  Search as SearchIcon,
  Share as ShareIcon,
  Storefront,
  SyncAlt,
  ViewList,
  ViewModule,
  ViewStream,
  Warning
} from '@material-ui/icons'
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core'
import React, { useState } from 'react'

import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import { HISTORY } from '../router'
import { SUBSCRIPTION_EDITORS } from '../../constants'
import { useFlags } from '../../providers/FlagsProvider'
import { useGetViewer } from '../../api/useGetViewer'

type SidebarProps = ISidebarProps

interface ISidebarProps {}
const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    icon: {
      minWidth: 42
    }
  })
)

export const Sidebar: React.FC<SidebarProps> = props => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<Array<string>>([])
  const flags = useFlags()
  const { data } = useGetViewer()
  const isSuperDuperAdmin = SUBSCRIPTION_EDITORS.includes(data?.viewer?.account?.emailAddress || 'NOT_EDITOR')

  const moduleTypes = [
    {
      key: 'ecommerce',
      name: 'Ecommerce',
      icon: <MonetizationOnIcon />,
      modules: [
        {
          name: 'Referrals',
          path: '/referrals',
          icon: <GroupAdd />
        },
        {
          name: 'Plus Renewal Failures',
          path: '/plus-renewal-failures',
          icon: <Warning />
        },
        {
          name: 'Plus Orders',
          path: '/orders',
          icon: <Money />
        },
        {
          name: 'Activated Subscriptions',
          path: '/plus',
          icon: <AddCircle />
        },
        ...(isSuperDuperAdmin
          ? [
              {
                name: 'Activation Codes',
                path: '/activation-codes',
                icon: <DeveloperMode />
              }
            ]
          : [])
      ]
    },
    {
      key: 'discover',
      name: 'Discover',
      icon: <SearchIcon />,
      modules: [
        {
          name: 'Paired Boards',
          path: '/boards',
          icon: <ViewModule />
        },
        {
          name: 'Digital Devices',
          path: '/digital-devices',
          icon: <LiveTv />
        },
        {
          name: 'Installables',
          path: '/installables',
          icon: <Apps />
        },
        {
          name: 'Marketplace Listings',
          path: '/marketplace-listings',
          icon: <Storefront />
        },
        {
          name: 'Marketplace features',
          path: '/marketplace',
          icon: <Storefront />
        },
        {
          name: 'Search Terms',
          path: '/search-terms',
          icon: <FindInPage />
        },
        {
          name: 'Developers',
          path: '/developers',
          icon: <Code />
        },
        {
          name: 'OS Versions',
          path: '/os-versions',
          icon: <Keyboard />
        }
      ]
    },
    {
      key: 'content',
      name: 'Messages',
      icon: <ViewList />,
      modules: [
        {
          name: (
            <Typography
              style={{
                color: '#f50057',
                fontWeight: 'bold',
                fontSize: 12
              }}>
              {flags.messagesV2 ? 'Back to V1 Messages' : 'Try V2 Messages'}
            </Typography>
          ),
          onClick: () => {
            flags.toggleV2Messages()
            HISTORY.push('/message-sets')
          },
          icon: flags.messagesV2 ? (
            <ExposureNeg1
              style={{
                color: '#f50057'
              }}
            />
          ) : (
            <ExposurePlus2 style={{ color: '#f50057' }} />
          )
        },
        ...(flags.messagesV2
          ? [
              {
                name: 'Message Set Submissions',
                path: '/submitted-message-sets',
                icon: <ShareIcon />
              }
            ]
          : []),
        {
          name: 'Message Sets',
          path: '/message-sets',
          icon: <ViewStream />
        },
        {
          name: 'Todays Picks',
          path: '/picks',
          icon: <LibraryAddCheck />
        },
        {
          name: 'Color Templates',
          path: '/color-templates',
          icon: <FormatPaint />
        },
        {
          name: 'Questions and Answers',
          path: '/questions-and-answers',
          icon: <QuestionAnswer />
        },
        ...(flags.messagesV2
          ? [
              {
                name: 'Feed Items',
                path: '/feed-items',
                icon: <DynamicFeed />
              }
            ]
          : [])
      ]
    },
    {
      key: 'factoryTools',
      name: 'Tools',
      icon: <Build />,
      modules: [
        {
          name: 'Notifications',
          path: '/notifications',
          icon: <Notifications />
        },
        {
          name: 'Reserve Pairing Codes',
          path: '/reserve-pairing-codes',
          icon: <ConfirmationNumber />
        },
        {
          name: 'Sync Provisioned Devices',
          path: '/sync-provisioned-devices',
          icon: <SyncAlt />
        },
        {
          name: 'Serial Exception Checker',
          path: '/serial-exception-checker',
          icon: <CodeOutlined />
        },
        {
          name: 'Hawkbit CSV',
          path: '/hawkbit',
          icon: <CloudUploadRounded />
        }
      ]
    }
  ]

  return (
    <>
      <List>
        <ListItem onClick={() => HISTORY.push('/')} button>
          <ListItemIcon className={classes.icon}>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>
        <Divider />
        {moduleTypes.map(moduleType => (
          <React.Fragment key={moduleType.key}>
            <ListItem
              button
              onClick={() => {
                if (expanded.includes(moduleType.key)) {
                  setExpanded(expanded.filter(item => item !== moduleType.key))
                } else {
                  setExpanded([...expanded, moduleType.key])
                }
              }}>
              <ListItemIcon className={classes.icon}>{moduleType.icon}</ListItemIcon>
              <ListItemText primary={moduleType.name} />
              {expanded.includes(moduleType.key) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expanded.includes(moduleType.key)} timeout='auto' unmountOnExit>
              {!moduleType.modules?.length && <Alert severity='info'>There are no modules for this type yet.</Alert>}
              <List>
                {moduleType?.modules?.map((module, index) => (
                  <ListItem
                    className={classes.nested}
                    onClick={module.onClick ? module.onClick : () => HISTORY.push(module.path)}
                    button
                    key={index}>
                    <ListItemIcon className={classes.icon}>{module.icon}</ListItemIcon>
                    <ListItemText primary={module.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </>
  )
}
