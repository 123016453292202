import { GetSuggestedSearchTerms } from './__generated__/GetSuggestedSearchTerms'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetSuggestedSearchTerms {
    suggestedSearchTerms: suggestedSearchTermsV2 {
      id
      searchTerms
    }
  }
`

export const useGetSuggestedSearchTerms = () => useQuery<GetSuggestedSearchTerms>(QUERY)
