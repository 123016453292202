import { Box, Chip, TextField } from '@material-ui/core'
import React, { useState } from 'react'

interface ITags {
  value: string
  onChange: (tags: string) => void
}

export const Tags = (props: ITags) => {
  const [text, setText] = useState('')

  const tags = props.value.split(',').filter(tag => tag !== '')

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
      }}>
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          onDelete={() => {
            props.onChange(tags.filter(t => t !== tag).join(','))
          }}
          style={{
            margin: 4
          }}
        />
      ))}
      <TextField
        value={text}
        variant='outlined'
        size='small'
        placeholder='Tags (comma separated)'
        fullWidth={tags.length === 0}
        onChange={e => {
          if (e.target.value.includes(',')) {
            props.onChange([...tags, ...text.split(',').map(tag => tag.trim())].filter(tag => tag !== '').join(','))
            setText('')
          } else {
            setText(e.target.value)
          }
        }}
        onBlur={() => {
          props.onChange([...tags, ...text.split(',').map(tag => tag.trim())].filter(tag => tag !== '').join(','))
          setText('')
        }}
      />
    </Box>
  )
}
