import { MarketplaceListingRouter, NewMarketplaceListingContainer } from '../marketplace-listings'
import React, { useEffect } from 'react'
import { Router as ReactRouter, Redirect, Route, Switch, useLocation } from 'react-router'

import { AccountDetailPage } from '../account-detail-page'
import { ActivationCodeRouter } from '../activation-code'
import { BoardRouter } from '../board'
import { ChannelDetail } from '../channel-detail'
import { ChannelsReport } from '../channels-report'
import { ColorTemplateRouterV1 } from '../color-template-v1'
import { ColorTemplateRouterV2 } from '../color-template-v2'
import { DashboardRouter } from '../dashboard'
import { DevelopersRouter } from '../developers'
import { DeviceBatchView } from '../device-batch-view/DeviceBatchView'
import { DeviceListPage } from '../device-list-page'
import { DevicePage } from '../device-page'
import { DigitalDevicesRouter } from '../digital-devices'
import { FeedItemRouterV2 } from '../feed-items-v2'
import { HardwareListPage } from '../hardware-list-page'
import { HardwarePage } from '../hardware-page'
import { HawkbitUploader } from '../hawkbit-uploader'
import { InstallablesRouter } from '../installables'
import { Installation } from '../installation'
import { ListPlusRenewalFailures } from '../list-plus-renewal-failures'
import { MarketplaceConfiguration } from '../marketplace-configuration'
import { MarketplaceListingFeatureTypeList } from '../marketplace-listing-feature-type-list'
import { MessageAppearanceRouterV1 } from '../message-appearance-v1'
import { MessageAppearanceRouterV2 } from '../message-appearance-v2'
import { MessageRouterV1 } from '../message-v1'
import { MessageRouterV2 } from '../message-v2'
import { MessageSetRouterV1 } from '../message-set-v1'
import { MessageSetRouterV2 } from '../message-set-v2'
import { NewInstallable } from '../installables'
import { NotificationsRouter } from '../notifications'
import { OrderPage } from '../order-page'
import { OrdersListPage } from '../orders-list-page'
import { OsVersion } from '../os-version'
import { OsVersionList } from '../os-version/OsVersionList'
import { PairingCodeRedirect } from '../pairing-code-redirect'
import { PaymentListPage } from '../payment-list-page'
import { PersonDetailPage } from '../person-detail-page'
import { PicksRouterV1 } from '../picks-v1'
import { PicksRouterV2 } from '../picks-v2'
import { PlusRouterV1 } from '../plus'
import { Principal } from '../principal'
import { QuestionsAndAnswers } from '../questions-and-answers'
import { ReferralsRouter } from '../referral'
import { ReportActivationsContainer } from '../report-activations-container'
import { ReportActivationsDeletedBoardsContainer } from '../report-activations-deleted-boards-container'
import { ReportActivationsOfflineBoardsContainer } from '../report-activations-offline-boards-container'
import { ReservePairingCodes } from '../reserve-pairing-codes'
import { SearchTerms } from '../search-terms'
import { SerialExceptionChecker } from '../serial-exception-checker'
import { SubmittedMessageSetRouterV2 } from '../message-set-submissions-v2'
import { Subscription } from '../subscription'
import { SuperadminListPage } from '../superadmin-list-page'
import { SyncProvisionedDevices } from '../sync-provisioned-devices'
import { Tenant } from '../tenant'
import { TenantList } from '../tenant-list'
import { UserListPage } from '../user-list-page'
import { YoctoRollout } from '../yocto-rollout'
import { createBrowserHistory } from 'history'
import { useFlags } from '../../providers/FlagsProvider'

type RouterProps = IRouterProps

interface IRouterProps {}

export const HISTORY = createBrowserHistory()

type RouteComponentMapping = { [key: string]: React.ComponentType }

const RouteWatcher = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/') {
      localStorage.setItem('currentPage', location.pathname)
    }
  }, [location])

  return null
}

export const Router: React.FC<RouterProps> = props => {
  const flags = useFlags()

  const detailPages: RouteComponentMapping = {
    devices: DevicePage,
    hardware: HardwarePage,
    person: PersonDetailPage,
    channel: ChannelDetail
  }

  const indexPages: RouteComponentMapping = {
    accounts: UserListPage,
    devices: DeviceListPage,
    tenants: TenantList,
    superadmins: SuperadminListPage,
    orders: OrdersListPage,
    payments: PaymentListPage,
    hardwares: HardwareListPage,
    users: UserListPage
  }

  const recordDetailRoutes = Object.keys(detailPages).map(model => (
    <Route key={model} path={`/${model}/:id`} component={detailPages[model]} />
  ))

  const recordIndexRoutes = Object.keys(indexPages).map(model => (
    <Route exact key={model} path={`/${model}`} component={indexPages[model]} />
  ))

  return (
    <ReactRouter history={HISTORY}>
      <RouteWatcher />
      <Switch>
        <Route exact path={`/pairing-code/:code`} component={PairingCodeRedirect} />
        <Route exact path={`/devices/yocto-status/:status?`} component={DeviceListPage} />
        {recordDetailRoutes}
        {recordIndexRoutes}
        <Route exact path={`/device-batch`} component={DeviceBatchView} />
        <Route exact path={`/account/:emailAddress`} component={AccountDetailPage} />
        <Route exact path={`/report/online-boards`} component={ReportActivationsContainer} />
        <Route exact path={`/report/offline-boards`} component={ReportActivationsOfflineBoardsContainer} />
        <Route exact path={`/new-installable`} component={NewInstallable} />
        <Route exact path={`/report/deleted-boards`} component={ReportActivationsDeletedBoardsContainer} />
        <Route exact path={`/new-marketplace-listing`} component={NewMarketplaceListingContainer} />
        <Route exact path={`/yocto-rollout`} component={YoctoRollout} />
        <Route exact path={`/marketplace`} component={MarketplaceConfiguration} />
        <Route exact path={`/report/channels`} component={ChannelsReport} />
        <Route exact path={`/os-version/:id`} component={OsVersion} />
        <Route exact path={`/os-versions`} component={OsVersionList} />
        <Route exact path={`/orders/:id`} component={OrderPage} />
        <Route exact path={`/plus-renewal-failures`} component={ListPlusRenewalFailures} />
        <Route exact path={'/marketplace-listing-feature-type/:id'} component={MarketplaceListingFeatureTypeList} />
        <Route exact path={`/subscription/:id`} component={Subscription} />
        <Route exact path={`/installation/:id`} component={Installation} />
        <Route exact path={`/tenant/:id`} component={Tenant} />
        <Route exact path={`/principal/:id`} component={Principal} />
        <Route exact path={`/hawkbit`} component={HawkbitUploader} />
        <Route exact path={`/reserve-pairing-codes`} component={ReservePairingCodes} />
        <Route exact path={`/sync-provisioned-devices`} component={SyncProvisionedDevices} />
        <Route exact path={`/serial-exception-checker`} component={SerialExceptionChecker} />
        <Route path={`/search-terms`} component={SearchTerms} />
        <Route path={`/questions-and-answers`} component={QuestionsAndAnswers} />
        <Route path={`/developers`} component={DevelopersRouter} />
        <Route path={'/installables'} component={InstallablesRouter} />
        <Route path={'/digital-devices'} component={DigitalDevicesRouter} />
        <Route path={`/referrals`} component={ReferralsRouter} />
        <Route path={'/marketplace-listings'} component={MarketplaceListingRouter} />
        <Route path='/color-templates' component={flags?.messagesV2 ? ColorTemplateRouterV2 : ColorTemplateRouterV1} />
        <Route path='/picks' component={flags.messagesV2 ? PicksRouterV2 : PicksRouterV1} />
        <Route path='/message-sets' component={flags.messagesV2 ? MessageSetRouterV2 : MessageSetRouterV1} />
        <Route path='/message' component={flags.messagesV2 ? MessageRouterV2 : MessageRouterV1} />
        <Route path={'/plus'} component={PlusRouterV1} />
        <Route path='/notifications' component={NotificationsRouter} />
        <Route path='/activation-codes' component={ActivationCodeRouter} />
        {flags.messagesV2 ? <Route path='/submitted-message-sets' component={SubmittedMessageSetRouterV2} /> : null}
        <Route path='/boards' component={BoardRouter} />
        <Route
          path='/message-appearance'
          component={flags.messagesV2 ? MessageAppearanceRouterV2 : MessageAppearanceRouterV1}
        />
        <Route path='/feed-items' component={FeedItemRouterV2} />
        <Route path={`/dashboard`} component={DashboardRouter} />
        <Route
          render={() => {
            return <Redirect to={`/dashboard/boards`} />
          }}
        />
      </Switch>
    </ReactRouter>
  )
}
