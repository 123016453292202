import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { FullModal, useToasts } from '@vestaboard/installables'
import React, { useState } from 'react'

import { Alert } from '@material-ui/lab'
import { Checkbox } from './Checkbox'
import { CloudUpload } from '@material-ui/icons'
import { csvToArray } from '../utils'

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: `calc(100vh - 280px)`,
    overflowY: 'auto',
    marginBottom: 42
  },
  fileUpload: {
    position: 'absolute',
    background: 'yellow',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    cursor: 'pointer'
  }
})

type TValidationType =
  | 'Date'
  | 'NullableDate'
  | 'Boolean'
  | 'Number'
  | 'NullableNumber'
  | 'String'
  | 'NullableString'
  | undefined

interface IImportCsvButton {
  mappings: string[]
  handleImport: (records: any[]) => void
  noHeader?: boolean
  mainButtonText?: string
  buttonText?: string
  buttonVariant?: string
  types?: {
    [key: string]: TValidationType
  }
}

const isFieldValid = (validationType: TValidationType, field?: string) => {
  return validationType === 'Date'
    ? !isNaN(Date.parse(field || ''))
    : validationType === 'NullableDate'
    ? !field || !isNaN(Date.parse(field))
    : validationType === 'Boolean'
    ? field === 'Yes' ||
      field === 'No' ||
      field === 'True' ||
      field === 'False' ||
      field === '1' ||
      field === '0' ||
      !field
    : validationType === 'Number'
    ? !isNaN(Number(field))
    : validationType === 'NullableNumber'
    ? !field || !isNaN(Number(field))
    : validationType === 'String'
    ? typeof field === 'string'
    : validationType === 'NullableString'
    ? !field || typeof field === 'string'
    : true
}

export const CsvImportButton = (props: IImportCsvButton) => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const [data, setData] = useState<null | {
    keys: string[]
    values: string[][]
    excluded: string[]
  }>(null)

  const isValid = data?.values.every(row =>
    row
      .filter(field => {
        return !data.excluded.includes(`${row}`)
      })
      .every((field, fieldIndex) => {
        const validationType = props.types?.[data.keys[fieldIndex]]
        return isFieldValid(validationType, field)
      })
  )

  return (
    <>
      <Button variant='outlined' color='default' endIcon={<CloudUpload />}>
        {props?.mainButtonText || 'IMPORT CSV'}
        <input
          type='file'
          className={classes.fileUpload}
          onChange={e => {
            if (e.target.files?.length) {
              const file = e.target.files[0]
              const reader = new FileReader()
              reader.onload = () => {
                if (file.name.split('.').pop() !== 'csv') {
                  addToast('File must be CSV', { appearance: 'error' })
                  return
                }

                try {
                  const [keys, ...values] = csvToArray(reader.result as string)

                  setData({
                    keys: props.mappings,
                    values: props.noHeader ? [keys, ...values] : values,
                    excluded: []
                  })
                } catch (e) {
                  addToast('Unable to parse CSV file', { appearance: 'error' })
                }
              }
              reader.readAsText(file)
            }
          }}
        />
      </Button>
      {!!data ? (
        <FullModal
          title={props.mainButtonText || 'Import CSV'}
          onClose={() => {
            setData(null)
          }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Box className={classes.tableContainer}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Include</TableCell>
                        {data.keys.map((key, keyIndex) => (
                          <TableCell key={`${key}-${keyIndex}`}>
                            <select
                              value={key}
                              onChange={e => {
                                setData({
                                  ...data,
                                  keys: data.keys.map((k, i) => (i === keyIndex ? e.target.value : k))
                                })
                              }}>
                              <option>NOT MAPPED</option>
                              {props.mappings.map(mapping => (
                                <option key={mapping} value={mapping}>
                                  {mapping}
                                </option>
                              ))}
                            </select>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.values.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              checked={!data.excluded.includes(`${index}`)}
                              handleCheck={() => {
                                if (data.excluded.includes(`${index}`)) {
                                  setData({
                                    ...data,
                                    excluded: data.excluded.filter(excluded => excluded !== `${index}`)
                                  })
                                } else {
                                  setData({
                                    ...data,
                                    excluded: [...data.excluded, `${index}`]
                                  })
                                }
                              }}
                            />
                          </TableCell>
                          {row.map((field: string, fieldIndex: number) => {
                            const validationType = props.types?.[data.keys[fieldIndex]]
                            const valid = isFieldValid(validationType, field)
                            return (
                              <TableCell
                                key={`field-${index}-${fieldIndex}-${valid ? 'valid' : 'invalid'}`}
                                style={{ color: valid ? '#00' : 'red' }}>
                                {field}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {!isValid ? (
                <Alert severity='error'>Some fields are invalid</Alert>
              ) : (
                <Button
                  variant='contained'
                  color={(props.buttonVariant as any) || 'primary'}
                  disabled={!isValid}
                  onClick={async () => {
                    const records = data.values
                      .filter(
                        (_row: string[], index: number) => !data.excluded.find(excluded => excluded === `${index}`)
                      )
                      .map(row => {
                        return row.reduce((prev: any, current: string, index: number) => {
                          const mapping = props.mappings.find(mapping => mapping === data.keys[index])

                          if (mapping) {
                            return {
                              ...prev,
                              [mapping]: current
                            }
                          }

                          return prev
                        }, {})
                      })

                    setLoading(true)

                    try {
                      await props.handleImport(records)
                      setLoading(false)
                      setData(null)
                      addToast('CSV Imported', { appearance: 'success' })
                    } catch (e) {
                      setLoading(false)
                      addToast('Unable to import CSV', { appearance: 'error' })
                    }
                  }}>
                  {!isValid ? 'Invalid' : props?.buttonText || 'Import'}
                </Button>
              )}
            </>
          )}
        </FullModal>
      ) : null}
    </>
  )
}
