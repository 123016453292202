import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import { Alert } from '@material-ui/lab'
import React from 'react'
import { useListTopSearchTerms } from '../../api/useListTopSearchTerms'

const RESULTS_PER_PAGE = 100

export const TrendingSearchTerms = () => {
  const { data, loading, error, fetchMore } = useListTopSearchTerms({
    input: {
      limit: RESULTS_PER_PAGE,
      cursor: null
    }
  })

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>{error.message}</Alert>
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Search Term</TableCell>
              <TableCell>Times Searched</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.listTopSearchTermsV2?.searchTermAnalytics || []).map(search => (
              <TableRow key={search.term}>
                <TableCell>"{search.term}"</TableCell>
                <TableCell>{search.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data?.listTopSearchTermsV2?.nextCursor ? (
        <Box paddingTop='1rem' display='flex' justifyContent='center'>
          <Button
            variant='outlined'
            onClick={async () => {
              if (!data?.listTopSearchTermsV2?.nextCursor) {
                return
              }

              await fetchMore({
                variables: {
                  input: {
                    limit: RESULTS_PER_PAGE,
                    cursor: data?.listTopSearchTermsV2?.nextCursor
                  }
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev
                  }
                  return {
                    listTopSearchTermsV2: {
                      __typename: prev.listTopSearchTermsV2.__typename,
                      searchTermAnalytics: [
                        ...prev.listTopSearchTermsV2.searchTermAnalytics,
                        ...fetchMoreResult.listTopSearchTermsV2.searchTermAnalytics
                      ],
                      nextCursor: fetchMoreResult.listTopSearchTermsV2.nextCursor
                    }
                  }
                }
              })
            }}>
            Load More
          </Button>
        </Box>
      ) : null}
    </>
  )
}
