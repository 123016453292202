import { ListTopSearchTerms, ListTopSearchTermsVariables } from './__generated__/ListTopSearchTerms'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query ListTopSearchTerms($input: ListSearchTermAnalyticsInputV2!) {
    listTopSearchTermsV2(input: $input) {
      searchTermAnalytics {
        term
        count
      }
      nextCursor
    }
  }
`

export const useListTopSearchTerms = (variables: ListTopSearchTermsVariables) =>
  useQuery<ListTopSearchTerms, ListTopSearchTermsVariables>(QUERY, {
    variables
  })
