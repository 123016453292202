import { Route, Switch, useHistory } from 'react-router'
import { Tab, Tabs } from '@material-ui/core'

import React from 'react'
import { Spacer } from '@vestaboard/installables'
import { SuggestedSearch } from './suggested'
import { TrendingSearchTerms } from './trending'

export const SearchTerms = () => {
  const history = useHistory()

  return (
    <>
      <Tabs
        value={history.location.pathname.includes('trending') ? 1 : 0}
        onChange={(_event, newValue) => {
          if (newValue === 1) {
            history.push(`/search-terms/trending`)
          } else {
            history.push(`/search-terms`)
          }
        }}>
        <Tab label='Suggested' />
        <Tab label='Trending' />
      </Tabs>
      <Spacer size='extraLarge' />
      <Switch>
        <Route exact path='/search-terms' component={SuggestedSearch} />
        <Route exact path='/search-terms/trending' component={TrendingSearchTerms} />
      </Switch>
    </>
  )
}
