import { SetSuggestedSearchTerms, SetSuggestedSearchTermsVariables } from './__generated__/SetSuggestedSearchTerms'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation SetSuggestedSearchTerms($input: SuggestedSearchTermsInput!) {
    setSuggestedSearchTerms: setSuggestedSearchTermsV2(input: $input) {
      id
      searchTerms
    }
  }
`

export const useSetSuggestedSearchTerms = () =>
  useMutation<SetSuggestedSearchTerms, SetSuggestedSearchTermsVariables>(MUTATION)
