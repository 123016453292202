import './App.css'

import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost'
import { Auth0Provider, useAuth0 } from './react-auth0-spa'
import { Backdrop, CircularProgress, CssBaseline, Divider, Drawer, IconButton } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { ThemeProvider, createTheme, makeStyles, useTheme } from '@material-ui/core/styles'

import { ApolloProvider } from '@apollo/react-hooks'
import { ChevronLeft } from '@material-ui/icons'
import { FlagsProvider } from './providers/FlagsProvider'
import { Router } from './components/router'
import { Sidebar } from './components/sidebar'
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import { ToastProvider } from '@vestaboard/installables'
import { TopNav } from './ui/TopNav'
import clsx from 'clsx'
import { drawerWidth } from './constants'
import { useGetViewer } from './api/useGetViewer'
import { useLDClient } from 'launchdarkly-react-client-sdk'

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID

export const appBarHeight = 64

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  }
}))

const GraphQLProvider: React.FC = props => {
  const { getTokenSilently } = useAuth0()

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [] // no types provided
      }
    }
  })

  const cache = new InMemoryCache({ fragmentMatcher })

  const client = new ApolloClient({
    uri: GRAPHQL_URL,
    cache,
    request: async operation => {
      operation.setContext({
        headers: {
          'X-Vestaboard-Token': await getTokenSilently()
        }
      })
    }
  })

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

const theme = createTheme({})

export const Providers: React.FC = props => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <FlagsProvider>
          <ToastProvider>
            <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
              <DrawerContextProvider>
                <CssBaseline />
                <Auth0Provider
                  domain={AUTH0_DOMAIN}
                  audience={`https://${AUTH0_DOMAIN}/api/v2/`}
                  client_id={AUTH0_CLIENT_ID}
                  redirect_uri={window.location.origin}
                  scope={`openid profile email`}>
                  <GraphQLProvider>{props.children}</GraphQLProvider>
                </Auth0Provider>
              </DrawerContextProvider>
            </SnackbarProvider>
          </ToastProvider>
        </FlagsProvider>
      </ThemeProvider>
    </>
  )
}

interface IDrawerContext {
  setDrawerOpen: (v: boolean) => any
  drawerOpen: boolean
}

const DrawerContext = React.createContext<IDrawerContext>({ drawerOpen: true, setDrawerOpen: () => {} })

const DrawerContextProvider: React.FC = props => {
  const [drawerOpen, setDrawerOpen] = React.useState(true)

  return (
    <DrawerContext.Provider
      value={{
        drawerOpen,
        setDrawerOpen
      }}>
      {props.children}
    </DrawerContext.Provider>
  )
}

export const useDrawerContext = () => useContext(DrawerContext)

const AuthenticatedApp = () => {
  const { logout } = useAuth0()

  const theme = useTheme()
  const classes = useStyles(theme)
  const { drawerOpen, setDrawerOpen } = useDrawerContext()
  const { data } = useGetViewer()
  const ldClient = useLDClient()


  useEffect(() => {
    if (ldClient && data?.viewer?.account?.person.id) {
      ldClient?.identify({
        key: data?.viewer?.account?.person.id
      })
    }
  }, [data, ldClient])

  if (!data?.viewer?.account?.person.id) {
    return (
      <Backdrop open>
        <CircularProgress color='inherit' />
      </Backdrop>
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopNav logout={logout} setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
      <Drawer
        variant='persistent'
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setDrawerOpen(false)}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <Sidebar />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen
        })}>
        <div className={classes.toolbar} />
        <Router />
      </main>
    </div>
  )
}

const App: React.FC = () => {
  const { loading: auth0Loading, isAuthenticated, loginWithRedirect } = useAuth0()

  if (auth0Loading) {
    return (
      <Backdrop open>
        <CircularProgress color='inherit' />
      </Backdrop>
    )
  }

  if (!auth0Loading && !isAuthenticated) {
    loginWithRedirect()
  }

  return <AuthenticatedApp />
}

export default App
