import {
  Button,
  ButtonBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, LoadMask, Spacer } from '@vestaboard/installables'
import React, { useEffect, useState } from 'react'
import { useGetSuggestedSearchTerms, useSetSuggestedSearchTerms } from '../../api'

import { Alert } from '@material-ui/lab'
import { Clear } from '@material-ui/icons'

export const SuggestedSearch = () => {
  const [terms, setTerms] = useState<string[]>([])
  const { data, loading, error } = useGetSuggestedSearchTerms()
  const [saveSearchTerms, { loading: saving }] = useSetSuggestedSearchTerms()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (data) {
      setTerms(data.suggestedSearchTerms.searchTerms)
    }
  }, [data])

  if (loading) {
    return <LoadMask />
  }

  if (error) {
    return <Alert severity='error'>{error.message}</Alert>
  }

  return (
    <div>
      {saving && <LoadMask />}
      <div style={{ width: '100%', maxWidth: 600 }}>
        <FlexHorizontal spaceBetween>
          <Typography variant='h4'>Suggested Search Terms</Typography>
          <Button
            onClick={() => {
              setTerms([...terms, ''])
            }}
            variant='contained'
            color='primary'>
            Add Term
          </Button>
        </FlexHorizontal>
      </div>
      <Spacer size='extraLarge' />
      <TableContainer
        component={Paper}
        style={{
          maxWidth: 600
        }}>
        <Table>
          <TableBody>
            {!terms.length ? (
              <TableRow>
                <TableCell>No terms found</TableCell>
              </TableRow>
            ) : null}
            {terms.map((term, index) => (
              <TableRow key={index}>
                <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    placeholder='Search Term'
                    value={term}
                    onChange={e => {
                      const newTerms = [...terms]
                      newTerms[index] = e.target.value
                      setTerms(newTerms)
                      setErrorMessage(null)
                    }}
                  />
                  <Spacer size='large' />
                  <ButtonBase
                    onClick={() => {
                      const newTerms = [...terms]
                      newTerms.splice(index, 1)
                      setTerms(newTerms)
                    }}>
                    <Clear />
                  </ButtonBase>
                </TableCell>
              </TableRow>
            ))}
            {errorMessage ? (
              <TableRow>
                <TableCell>
                  <Alert severity='error'>{errorMessage}</Alert>
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>
                <Button
                  size='large'
                  onClick={async () => {
                    const emptyTerms = terms.filter(term => term.trim() === '')
                    if (emptyTerms.length) {
                      setErrorMessage('Please fill out all terms')
                      return
                    }

                    await saveSearchTerms({
                      variables: {
                        input: {
                          searchTerms: terms
                        }
                      }
                    })
                  }}
                  variant='contained'
                  color='primary'>
                  Save
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
