import React, { useCallback } from 'react'

import { Box } from '@material-ui/core'
import { CsvImportButton } from '../../../ui/CsvImportButton'
import { LoadMask } from '@vestaboard/installables'
import { useUnpairDevice } from '../../../api'

interface IResponse {
  deviceId?: string
}

export const BatchUnPair = () => {
  const [unpairDeviceMutation, { loading }] = useUnpairDevice()

  const unpairDeviceRecursively = useCallback(
    async (deviceIds: string[]) => {
      if (deviceIds.length === 0) {
        return
      }
      const deviceId = deviceIds.pop()

      if (deviceId) {
        await unpairDeviceMutation({
          variables: {
            deviceId
          }
        })
      }

      await unpairDeviceRecursively(deviceIds)
    },
    [unpairDeviceMutation]
  )

  return (
    <>
      {loading ? <LoadMask /> : null}
      <Box width={7} />
      <CsvImportButton
        mainButtonText='Batch Un-Pair'
        buttonText='Un-Pair Devices'
        buttonVariant='secondary'
        mappings={['deviceId']}
        handleImport={async records => {
          const boardDeviceAssociationIds = (records as IResponse[])
            .filter(record => !!record.deviceId)
            .map(record => record.deviceId as string)

          await unpairDeviceRecursively(boardDeviceAssociationIds)
        }}
      />
    </>
  )
}
