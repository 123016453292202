import {
  MarketplaceListingDetailQuery,
  MarketplaceListingDetailQueryVariables
} from './__generated__/MarketplaceListingDetailQuery'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query MarketplaceListingDetailQuery($id: String!) {
    installableCategories {
      id
      created
      title
    }
    marketplaceListingFeatureTypes(input: { includeDynamic: false }) {
      id
      title
    }
    languages {
      id
      language
    }
    publicInstallables {
      id
      created
      title
      description
      icon
      configurationSchema
    }
    messageSets {
      id
      title
    }
    marketplaceListing(id: $id) {
      id
      releaseDate
      icon
      free
      hiddenFromProduction
      featureTypes {
        id
        marketplaceListingFeatureType {
          id
        }
      }
      iconMedia {
        id
      }
      carouselMedia1Ref {
        id
      }
      carouselMedia2Ref {
        id
      }
      carouselMedia3Ref {
        id
      }
      isFeatured
      categories {
        id
        title
      }
      configuration
      carouselMedia1
      carouselMedia2
      carouselMedia3
      language {
        id
        language
      }
      version
      installable {
        id
        title
        created
        description
        icon
        hideFromInstallablesList
        installationsAreSingleton
        configurationSchema
        subscriptionsCanBeRenamed
        developer {
          id
          title
        }
      }
      messageSet {
        id
        title
      }
      developer {
        id
        title
      }
      title
      description
      stagingOnly
      shortDescription
      questionAndAnswerId
      tags
    }
  }
`

export const useGetMarketplaceListing = (variables: MarketplaceListingDetailQueryVariables) =>
  useQuery<MarketplaceListingDetailQuery, MarketplaceListingDetailQueryVariables>(QUERY, {
    variables
  })
