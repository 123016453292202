import { UnpairDevice, UnpairDeviceVariables } from './__generated__/UnpairDevice'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation UnpairDevice($deviceId: String!) {
    unpairDeviceWithBoard(input: { pairedDeviceId: $deviceId }) {
      unpaired
    }
  }
`

export const useUnpairDevice = () => {
  return useMutation<UnpairDevice, UnpairDeviceVariables>(MUTATION)
}
