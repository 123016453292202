import * as React from 'react'

import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  ListItem,
  MenuItem,
  Link as MuiLink,
  Select,
  Switch,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FlexHorizontal, Spacer, useToasts } from '@vestaboard/installables'
import {
  useAddMarketplaceListingFeature,
  useGetMarketplaceListing,
  useRemoveMarketplaceListingFeature,
  useUpdateMarketplaceListing
} from '../../../api'

import { Alert } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { MediaUploadButton } from '../../../ui/MediaUploadButton'
import { SelectDeveloper } from '../../developers'
import { SelectQa } from '../../questions-and-answers'
import { Tags } from './Tags'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useMigrateMarketplaceListing } from '../hooks/useMigrateMarketplaceListing'

type MarketplaceListingDetailProps = IMarketplaceListingDetailProps

interface IMarketplaceListingDetailProps {
  id: string
}

interface IConfigurationSchemaFormProps {
  values: any
  onChange?: (values: any) => void
  schema?: {
    fields: {
      type: 'InstallableConfigurationPickListField'
      title: string
      description: string
      options: string[]
      allowMultiple: boolean
      defaultValue?: number
    }[]
  }
}
type ConfigurationSchemaFormProps = IConfigurationSchemaFormProps

const ConfigurationSchemaForm: React.FC<ConfigurationSchemaFormProps> = props => {
  const [fieldValues, setFieldValues] = React.useState(props.values)

  React.useEffect(() => {
    props.onChange?.(fieldValues)
  }, [fieldValues, props])

  if (!props.schema) return null

  return (
    <>
      {(props.schema.fields || []).map(field => {
        if (field.type === 'InstallableConfigurationPickListField') {
          return (
            <TableRow>
              <TableCell>{field.title}</TableCell>
              <TableCell>
                <Select
                  variant='outlined'
                  multiple
                  value={fieldValues[field.title] ?? []}
                  onChange={e => setFieldValues({ ...fieldValues, [field.title]: e.target.value as string[] })}>
                  {field.options.map(option => (
                    <ListItem value={option}>{option}</ListItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
          )
        }

        if (field.type === 'InstallableConfigurationNumberBooleanField') {
          const selected =
            fieldValues[field.title] === undefined ? !!field.defaultValue || false : !!fieldValues[field.title]

          return (
            <TableRow>
              <TableCell>{field.title}</TableCell>
              <TableCell>
                <Switch
                  checked={selected}
                  onChange={e => {
                    setFieldValues({ ...fieldValues, [field.title]: selected ? 0 : 1 })
                  }}
                />
              </TableCell>
            </TableRow>
          )
        }

        if (field.type === 'InstallableConfigurationStringField') {
          return (
            <TableRow>
              <TableCell>{field.title}</TableCell>
              <TableCell>
                <TextField
                  multiline
                  value={fieldValues[field.title] ?? ''}
                  onChange={e => setFieldValues({ ...fieldValues, [field.title]: e.target.value as string })}
                  placeholder={field.description}
                  variant='outlined'
                  fullWidth
                />
              </TableCell>
            </TableRow>
          )
        }

        return null
      })}
    </>
  )
}

export const MarketplaceListingDetail: React.FC<MarketplaceListingDetailProps> = props => {
  const history = useHistory()
  const { id } = props
  const [mutate] = useUpdateMarketplaceListing()
  const [addMarketplaceListingFeatureMutation] = useAddMarketplaceListingFeature()
  const [removeMarketplaceListingFeatureMutation] = useRemoveMarketplaceListingFeature()
  const [migrate] = useMigrateMarketplaceListing()
  const { data, loading, refetch } = useGetMarketplaceListing({ id })
  const { addToast } = useToasts()

  const [installable, setInstallable] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [isSaving, setIsSaving] = React.useState(false)
  const [description, setDescription] = React.useState('')
  const [iconUrl, setIconUrl] = React.useState(null as string | null)
  const [iconRef, setIconRef] = React.useState(null as string | null)
  const [year, setYear] = React.useState(0)
  const [month, setMonth] = React.useState(0)
  const [day, setDay] = React.useState(0)
  const [developer, setDeveloper] = React.useState<string | null>(null)
  const [messageSet, setMessageSet] = React.useState<string | null>(null)
  const [configuration, setConfiguration] = React.useState({} as any)
  const [categories, setCategories] = React.useState([] as string[])
  const [tags, setTags] = React.useState('')
  const [language, setLanguage] = React.useState(null as string | null | undefined)
  const [version, setVersion] = React.useState(null as string | null | undefined)
  const [carouselMedia1, setCarouselMedia1] = React.useState(null as string | null)
  const [carouselMedia2, setCarouselMedia2] = React.useState(null as string | null)
  const [carouselMedia3, setCarouselMedia3] = React.useState(null as string | null)
  const [carouselMedia1Ref, setCarouselMedia1Ref] = React.useState(null as string | null)
  const [carouselMedia2Ref, setCarouselMedia2Ref] = React.useState(null as string | null)
  const [carouselMedia3Ref, setCarouselMedia3Ref] = React.useState(null as string | null)
  const [shortDescription, setShortDescription] = React.useState(null as string | null)
  const [isFeatured, setIsFeatured] = React.useState(null as boolean | null)
  const [stagingOnly, setStagingOnly] = React.useState(false)
  const [free, setFree] = React.useState(false)
  const [showErrors, setShowErrors] = React.useState(false)
  const [featureTypes, setFeatureTypes] = React.useState<string[]>([])
  const [questionAndAnswerId, setQuestionAndAnswerId] = React.useState(null as string | null)

  React.useEffect(() => {
    if (data?.marketplaceListing.installable) {
      const date = moment(parseInt(data.marketplaceListing.releaseDate)).format('YYYY-MM-DD')
      const dateParts = date.split('-')

      setInstallable(data?.marketplaceListing.installable.id)
      setTitle(data?.marketplaceListing.title ?? '')
      setDescription(data?.marketplaceListing.description ?? '')
      setIconUrl(data?.marketplaceListing.icon ?? '')
      setDeveloper(data?.marketplaceListing.developer?.id ?? '')
      setYear(parseInt(dateParts[0]))
      setMonth(parseInt(dateParts[1]))
      setDay(parseInt(dateParts[2]))
      setCategories(data?.marketplaceListing.categories?.map(c => c.id))
      setTags(data?.marketplaceListing.tags ?? '')
      setMessageSet(data?.marketplaceListing.messageSet?.id ?? null)
      setCarouselMedia1(data?.marketplaceListing.carouselMedia1)
      setCarouselMedia2(data?.marketplaceListing.carouselMedia2)
      setCarouselMedia3(data?.marketplaceListing.carouselMedia3)
      setLanguage(data?.marketplaceListing.language?.id)
      setVersion(data?.marketplaceListing.version)
      setShortDescription(data?.marketplaceListing.shortDescription)
      setIsFeatured(data?.marketplaceListing.isFeatured)
      setIconRef(data?.marketplaceListing.iconMedia?.id ?? null)
      setStagingOnly(data?.marketplaceListing.stagingOnly ?? false)
      setFree(data?.marketplaceListing.free ?? false)
      setFeatureTypes(
        data?.marketplaceListing.featureTypes
          .map(featureType => featureType?.marketplaceListingFeatureType?.id as string)
          .filter(id => !!id)
      )
      setQuestionAndAnswerId(data.marketplaceListing.questionAndAnswerId ?? null)
    }
  }, [data])

  const save = async () => {
    setShowErrors(true)

    if (!year) {
      return
    }

    setShowErrors(false)
    setIsSaving(true)

    const existingFeatureTypes = data?.marketplaceListing.featureTypes
      .map(featureType => featureType?.marketplaceListingFeatureType?.id as string)
      .filter(featureType => !!featureType)

    const featureTypesToRemove = (data?.marketplaceListing.featureTypes || []).filter(
      featureType => !featureTypes.includes(featureType?.marketplaceListingFeatureType?.id as string)
    )

    await Promise.all(
      featureTypesToRemove.map(
        async featureType =>
          await removeMarketplaceListingFeatureMutation({
            variables: {
              input: {
                id: featureType.id
              }
            }
          })
      )
    )

    await Promise.all(
      featureTypes
        .filter(featureType => !existingFeatureTypes?.includes(featureType))
        .map(
          async featureType =>
            await addMarketplaceListingFeatureMutation({
              variables: {
                input: {
                  marketplaceListingFeatureType: featureType,
                  marketplaceListingId: id
                }
              }
            })
        )
    )

    await mutate({
      variables: {
        input: {
          id,
          title,
          description,
          releaseMonth: month,
          releaseYear: year,
          releaseDay: day,
          developer: developer ?? '',
          iconUrl: (iconRef ?? iconUrl)!!,
          installable,
          messageSet,
          configuration: JSON.stringify(configuration),
          categories,
          language,
          version,
          carouselImage1: carouselMedia1Ref ?? carouselMedia1,
          carouselImage2: carouselMedia2Ref ?? carouselMedia2,
          carouselImage3: carouselMedia3Ref ?? carouselMedia3,
          shortDescription,
          featured: isFeatured,
          stagingOnly,
          free,
          questionAndAnswerId,
          tags: (tags || '')
            .split(',')
            .map(tag => tag.trim())
            .join(',')
        }
      }
    })
    await refetch()

    await migrate({
      variables: {
        id
      }
    })

    addToast('Marketplace Listing saved', {
      appearance: 'success',
      autoDismiss: true
    })

    setIsSaving(false)
  }

  if (loading) return <CircularProgress />

  if (!data) return <Alert severity='error'>Marketplace Listing not found</Alert>

  const dateValue = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`

  const getConfigSchema = (str: string) => {
    try {
      return JSON.parse(str)
    } catch (err) {
      return {}
    }
  }
  return (
    <>
      <Box>
        <Card style={{ maxWidth: 850 }}>
          <Table>
            <TableRow>
              <TableCell>Marketplace Listing ID</TableCell>
              <TableCell>
                <TextField value={data.marketplaceListing.id} disabled variant='outlined' fullWidth />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Developer</TableCell>
              <TableCell>
                <SelectDeveloper value={developer} setValue={setDeveloper} />
              </TableCell>
              <TableCell>
                <Button disabled={!developer} onClick={() => history.push(`/developers/${developer}`)}>
                  Details
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Installable</TableCell>
              <TableCell>
                <Select
                  required
                  error={showErrors && !installable}
                  value={installable}
                  onChange={e => setInstallable(e.target.value as string)}
                  variant='outlined'
                  fullWidth>
                  {data.publicInstallables
                    .sort((d1, d2) => d1?.title?.localeCompare(d2?.title ?? '') ?? 0)
                    .map(d => (
                      <MenuItem value={d.id}>{d.title}</MenuItem>
                    ))}
                </Select>
              </TableCell>
              <TableCell>
                <Button disabled={!installable} onClick={() => history.push(`/installables/${installable}`)}>
                  Details
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Message set (optional)</TableCell>
              <TableCell>
                <Select
                  variant='outlined'
                  fullWidth
                  value={messageSet}
                  onChange={e => setMessageSet(e.target.value as string)}>
                  {data.messageSets
                    .sort((d1, d2) => d1?.title?.localeCompare(d2?.title ?? '') ?? 0)
                    .map(d => (
                      <MenuItem value={d.id}>{d.title}</MenuItem>
                    ))}
                </Select>
              </TableCell>
              <TableCell>
                <Button disabled={!messageSet} onClick={() => history.push(`/message-sets/${messageSet}`)}>
                  Details
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Q&A set (optional)</TableCell>
              <TableCell>
                <SelectQa value={questionAndAnswerId} setValue={setQuestionAndAnswerId} />
              </TableCell>
              <TableCell>
                <Button
                  disabled={!questionAndAnswerId}
                  onClick={() => history.push(`/questions-and-answers/${questionAndAnswerId}`)}>
                  Details
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Categories</TableCell>
              <TableCell>
                <Select
                  multiple
                  multiline
                  fullWidth
                  value={categories}
                  variant='outlined'
                  onChange={e => setCategories(e.target.value as string[])}>
                  {data.installableCategories
                    .sort((d1, d2) => d1?.title?.localeCompare(d2?.title ?? '') ?? 0)
                    .map(d => (
                      <MenuItem value={d.id}>{d.title}</MenuItem>
                    ))}
                </Select>
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Feature Types</TableCell>
              <TableCell>
                {data.marketplaceListingFeatureTypes
                  .sort((d1, d2) => d1?.title?.localeCompare(d2?.title ?? '') ?? 0)
                  .map(d => (
                    <Box key={d.id}>
                      <Checkbox
                        color='primary'
                        checked={featureTypes.includes(d.id)}
                        onChange={() => {
                          if (featureTypes.includes(d.id)) {
                            setFeatureTypes(featureTypes => featureTypes.filter(type => type !== d.id))
                          } else {
                            setFeatureTypes(featureTypes => [...featureTypes, d.id])
                          }
                        }}
                      />{' '}
                      <MuiLink component={Link} to={`/marketplace-listing-feature-type/${d.id}`} color='textPrimary'>
                        {d.title}
                      </MuiLink>
                    </Box>
                  ))}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Language</TableCell>
              <TableCell>
                <Select
                  variant='outlined'
                  fullWidth
                  value={language}
                  onChange={e => setLanguage(e.target.value as string)}>
                  {data.languages.map(d => (
                    <MenuItem value={d.id}>{d.language}</MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell>
                <TextField
                  required
                  error={showErrors && !version}
                  onChange={e => setVersion(e.target.value as string)}
                  value={version}
                  variant='outlined'
                  fullWidth
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </Table>
        </Card>
        <Spacer size='extraLarge' />
        <FlexHorizontal spaceBetween>
          <Typography variant='h6'>Listing Details</Typography>
        </FlexHorizontal>
        <Card style={{ marginTop: 30, maxWidth: 850 }}>
          <Table>
            <TableRow>
              <TableCell style={{ width: 300 }}>Title</TableCell>
              <TableCell>
                <TextField
                  required
                  error={showErrors && !title}
                  onChange={e => setTitle(e.target.value as string)}
                  value={title}
                  variant='outlined'
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>
                <TextField
                  required
                  error={showErrors && !description}
                  onChange={e => setDescription(e.target.value as string)}
                  value={description}
                  variant='outlined'
                  fullWidth
                  multiline
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Short Description</TableCell>
              <TableCell>
                <TextField
                  required
                  onChange={e => setShortDescription(e.target.value as string)}
                  value={shortDescription}
                  fullWidth
                  variant='outlined'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Release date</TableCell>
              <TableCell>
                <TextField
                  variant='outlined'
                  fullWidth
                  error={showErrors && !year}
                  required
                  type='date'
                  onChange={e => {
                    const date = e.target.value.split('-')

                    setYear(parseInt(date[0]))
                    setMonth(parseInt(date[1]))
                    setDay(parseInt(date[2]))
                  }}
                  value={dateValue}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Is Featured</TableCell>
              <TableCell>
                <Switch checked={isFeatured ?? false} onChange={e => setIsFeatured(e.target.checked)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Only available to internal users (staging mode)</TableCell>
              <TableCell>
                <Switch checked={stagingOnly} onChange={e => setStagingOnly(e.target.checked)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Available for free to users without Plus</TableCell>
              <TableCell>
                <Switch checked={free} onChange={e => setFree(e.target.checked)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Deep link to install</TableCell>
              <TableCell>
                <TextField
                  variant='outlined'
                  fullWidth
                  value={`https://platform.vestaboard.com/deeplink/marketplace-listing/${id}/install/ref=deeplink`}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tags</TableCell>
              <TableCell>
                <Tags onChange={setTags} value={tags} />
              </TableCell>
            </TableRow>
          </Table>
        </Card>
        <Spacer size='extraLarge' />
        <FlexHorizontal spaceBetween>
          <Typography variant='h6'>Listing Media</Typography>
        </FlexHorizontal>
        <Card style={{ marginTop: 30, maxWidth: 850 }}>
          <Table>
            <TableRow>
              <TableCell>Icon</TableCell>
              <TableCell>
                <MediaUploadButton previewUrl={iconUrl} mediaId={iconRef} onUpload={id => setIconRef(id)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carousel Media 1</TableCell>
              <TableCell>
                <MediaUploadButton
                  previewUrl={carouselMedia1}
                  mediaId={carouselMedia1Ref}
                  onUpload={id => setCarouselMedia1Ref(id)}
                  aspectRatio={16 / 9}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carousel Media 2</TableCell>
              <TableCell>
                <MediaUploadButton
                  previewUrl={carouselMedia2}
                  mediaId={carouselMedia2Ref}
                  onUpload={id => setCarouselMedia2Ref(id)}
                  aspectRatio={16 / 9}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carousel Media 3</TableCell>
              <TableCell>
                <MediaUploadButton
                  previewUrl={carouselMedia3}
                  mediaId={carouselMedia3Ref}
                  onUpload={id => setCarouselMedia3Ref(id)}
                  aspectRatio={16 / 9}
                />
              </TableCell>
            </TableRow>
            <ConfigurationSchemaForm
              values={JSON.parse(data.marketplaceListing.configuration ?? '{}')}
              onChange={values => setConfiguration(values)}
              schema={getConfigSchema(
                data.publicInstallables.find(i => i.id === installable)?.configurationSchema || '{}'
              )}
            />
          </Table>
        </Card>
      </Box>
      <Spacer size='extraLarge' />
      <FlexHorizontal>
        <Button
          size='large'
          variant='contained'
          onClick={() => {
            history.push('/marketplace-listings')
          }}>
          Cancel
        </Button>
        <Box width={24} />
        <Button disabled={isSaving} onClick={save} variant='contained' size='large' color='primary'>
          Save
        </Button>
      </FlexHorizontal>
    </>
  )
}
